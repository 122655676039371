<template>
  <div class="flex flex-col h-dvh overflow-hidden">
    <div class="border-b">
      <RouterView name="Header" @open-menu="isMenuOpened = !isMenuOpened" :is-menu-open="isMenuOpened" />
    </div>
    <div class="flex flex-1 overflow-hidden">
      <aside class="flex-none  min-w-[0px] w-full md:w-[300px] fixed bottom-0 top-[68px] md:top-0 z-40 md:relative transition-transform" :class="isMenuOpened ? '' : '-translate-x-full md:translate-x-0'">
        <RouterView name="LeftSidebar" />
      </aside>
      <main class="flex-1 overflow-y-auto h-full">
        <RouterView />
      </main>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const isMenuOpened = ref(false)
const route = useRoute()

watch(route, () => {
  isMenuOpened.value = false
})
</script>
