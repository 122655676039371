import { createHead } from 'unhead'
import { createSSRApp } from 'vue'
import { createVfm } from 'vue-final-modal'

import App from '@/App.vue'
import useFormkit from '@/core/formkit.js'
import http from '@/core/http.js'
import { useTanstack } from '@/core/tanstack.js'
import isBrowser from '@/utils/is-browser.js'
import { ZiggyVue } from 'ziggy-js'

export default function createApp(router, pinia, state) {
  const app = createSSRApp(App)
  const modals = createVfm()
  createHead()
  const { VueQueryPlugin, queryClient } = useTanstack(state)

  // Provide global variables.
  if (isBrowser) {
    window.$http = http
  }

  // Initialize packages.
  app.use(router)
  app.use(ZiggyVue)
  app.use(modals)
  app.use(pinia)
  app.use(VueQueryPlugin, { queryClient, enableDevtoolsV6Plugin: true })

  useFormkit(app)

  return app
}
