<script setup>
import { useRoute } from 'vue-router'

const route = useRoute()

function filterBySingleDot(array) {
  return array.filter(item => {
    const dotCount = (item.name.match(/\./g) || []).length
    return dotCount === 1
  })
}

const navigation = filterBySingleDot(route.matched[0].children)
</script>

<template>
  <aside class="px-6 py-4 bg-background h-full overflow-y-auto">
    <nav class="flex flex-col gap-y-1">
      <component
        v-for="link in navigation"
        :key="link.name"
        :to="{ name: link.name }"
        :is="link?.event ? 'button' : 'router-link'"
        class="flex items-center gap-2 font-semibold whitespace-nowrap pl-3 pr-2 py-2 rounded-md"
        exact-active-class="text-primary bg-primary/10"
      >
        <component
          v-if="link.icon"
          :is="link.icon"
          class="size-6 flex-none"
        />
        {{ link.label }}
      </component>
    </nav>
  </aside>
</template>
