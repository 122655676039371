<script setup>

</script>

<template>
  <div class="text-sm text-rose-600">Импут не доступен</div>
</template>

<style scoped>

</style>
