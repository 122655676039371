import { IconLoader2 } from '@tabler/icons-vue'

export { default as LoadingView } from './LoadingView.js'

export const config = {
  icon: IconLoader2,
  iconAnimation: 'animate-spin',
  contentWrapper: 'flex flex-col min-h-[250px]',
  iconWrapper: 'w-10 h-10 flex m-auto',
  iconClass: 'm-auto',
  transition: {
    enterActiveClass: 'ease-out duration-300',
    enterFromClass: 'opacity-0',
    enterToClass: 'opacity-100',
    leaveActiveClass: 'ease-in duration-200',
    leaveFromClass: 'opacity-100',
    leaveToClass: 'opacity-0',
  },
}
