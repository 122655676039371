import { defineComponent, h, Transition } from 'vue'

import { config } from '.'

export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { slots }) {
    return () =>
      h(
        Transition,
        { mode: 'out-in', ...config.transition },
        {
          default: () =>
            h(
              'div',
              { key: props.isLoading, class: config.contentWrapper },
              props.isLoading
                ? h(
                    'div',
                    { class: config.iconWrapper },
                    h(config.icon, {
                      class: `${config.iconAnimation} ${config.iconClass}`,
                    }),
                  )
                : slots.default
                  ? slots.default()
                  : null,
            ),
        },
      )
  },
})
