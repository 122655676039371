<template>
  <div
    class="transition-colors py-4 relative z-50"
    :class="{
      'bg-background/80 backdrop-blur-lg shadow': !withoutProfile,
    }"
  >
    <div class="px-6 flex items-center justify-between">
      <div class="flex gap-4">
        <Logo />
        <Button
          class="!px-2.5 md:hidden"
          type="secondary"
          @click="$emit('open-menu')"
        >
          <component :is="!isMenuOpen ? IconMenu : IconX" class="size-5" />
        </Button>
      </div>

      <div class="flex items-center">
        <ThemeSwitcher class="mr-2" />
        <Profile v-if="!withoutProfile" />
        <Button
          v-if="auth.isLoggedIn"
          @click="auth.logout"
          class="ml-2 !px-2.5"
          type="secondary"
        >
          <IconLogout class="w-5 h-5" />
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from '@/stores/auth.js'

import { IconLogout, IconMenu, IconX } from '@tabler/icons-vue'

import Profile from '@/components/common/Profile.vue'
import ThemeSwitcher from '@/components/common/ThemeSwitcher.vue'
import { Button } from '@/components/ui/Button'

import Logo from '../common/Logo.vue'

const props = defineProps({
  withoutProfile: {
    type: Boolean,
    default: false,
  },
  isMenuOpen: {
    type: Boolean,
    default: false,
  },
})

const auth = useAuthStore()
</script>
