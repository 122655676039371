<template>
  <div>
    <Button
      v-if="!auth.isLoggedIn"
      is="router-link"
      to="/login"
      variant="secondary"
      size="md"
      text="Войти"
    />
    <router-link v-else class="lg:w-min w-full" to="/workspace">
      <Button
        class="w-full whitespace-nowrap [&>span]:hidden [&>svg]:mr-0 md:[&>span]:inline md:[&>svg]:mr-1.5"
        reverse
        :text="auth.user?.title"
        variant="secondary"
        size="sm"
        :icon="IconUser"
      />
    </router-link>
  </div>
</template>

<script setup>
import { useAuthStore } from '@/stores/auth.js'

import { IconUser } from '@tabler/icons-vue'

// import { Button } from '@/components/ui/Button'
import Button from '@/components/glass/button'

const auth = useAuthStore()
</script>
