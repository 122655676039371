import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

import { useTenancyStore } from '@/stores/tenancy.js'

import useHttp from '@/core/http.js'
import useSSRCookies from '@/utils/get-cookies.js'
import isBrowser from '@/utils/is-browser.js'

const defaultOptions = {
  path: '/',
  domain: isBrowser ? window.location.hostname : null,
}

const cookieName = 'qbcat'

export const useAuthStore = defineStore('auth', () => {
  const cookies = useSSRCookies()

  const router = useRouter()

  const token = ref(cookies.get(cookieName))
  const user = ref(null)
  const returnUrl = ref(null)

  const afterLoginHooks = ref([])

  const tenancy = useTenancyStore()

  const isLoggedIn = computed(() => !!token.value)

  const loginRequest = (data) => {
    return useHttp('/v1/public/auth/login', {
      method: 'POST',
      body: data,
    })
  }

  const setCookieToken = (t, remember = true) => {
    const date = new Date()
    date.setFullYear(date.getFullYear() + 1)

    cookies.remove(cookieName, defaultOptions)
    cookies.set(cookieName, t, {
      ...defaultOptions,
      ...(remember
        ? {
            expires: date,
          }
        : {
            maxAge: undefined,
          }),
    })
    token.value = t
  }

  const login = async (data) => {
    return new Promise((resolve, reject) => {
      loginRequest(data)
        .then(({ token }) => {
          setCookieToken(token, data.remember)
          fetchUser().then(() => {
            if (returnUrl.value !== false) {
              router.push(
                returnUrl.value || (user.is_public ? '/' : '/workspace'),
              )
              returnUrl.value = null
            }
          })

          resolve()
        })
        .catch((e) => reject(e))
    })
  }

  const loginViaSocial = async (body) => {
    return await useHttp('/v1/public/auth/ssa', {
      method: 'POST',
      body,
    })
  }

  const loginWithToken = async (token) => {
    setCookieToken(token)
    await fetchUser()
  }

  const logout = async (redirect = true) => {
    token.value = null
    user.value = null
    cookies.remove(cookieName, defaultOptions)
    tenancy.leaveTenant()

    if (redirect) {
      await router.push('/login')
    }
  }

  const fetchUser = () => {
    return new Promise(async (resolve) => {
      if (token.value) {
        useHttp('/v1/private/auth/me', {
          method: 'GET',
        })
          .then(({ resource }) => {
            user.value = resource
            resolve()
          })
          .catch(async () => {
            await logout()
            resolve()
          })
      } else {
        await logout(false)
        resolve()
      }
    })
  }

  return {
    token,
    user,
    returnUrl,
    login,
    loginWithToken,
    loginViaSocial,
    isLoggedIn,
    logout,
    fetchUser,
    afterLoginHooks,
  }
})
