import { $fetch } from 'ofetch'
import { onServerPrefetch } from 'vue'
import { toast } from 'vue-sonner'

import { useAuthStore } from '@/stores/auth.js'

import isBrowser from '@/utils/is-browser.js'

const http = $fetch.create({
  baseURL: isBrowser
    ? (import.meta.env.VITE_API_ENDPOINT ?? '/api')
    : 'http://127.0.0.1/api',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-LOCALE': 'ru', // TODO: Change
    'X-Requested-With': 'XMLHttpRequest',
    'X-TIMEZONE': Intl?.DateTimeFormat().resolvedOptions().timeZone,
  },
  onRequest: async ({ request, options }) => {
    const auth = useAuthStore()
    // Sync token
    if (auth.token) {
      options.headers = {
        ...options.headers,
        ...options.headersExtend,
        Authorization: `Bearer ${auth.token}`,
      }
    }
  },
  onResponseError({ response }) {
    if (response.status === 419) {
      toast.error('Ой!', {
        description: 'Вы делаете слишком много запросов.',
      })
    }
  },
})

export function getBaseUrl(suffix = '/api') {
  return isBrowser
    ? ((import.meta.env.VITE_API_ENDPOINT ?? '') + suffix)
    : 'http://127.0.0.1' + suffix
}

export default async function useHttp(url, options = {}) {
  const fixURL = url.replace('/api', '')
  const fetchRequest = async () => {
    try {
      return await http(fixURL, options)
    } catch (error) {
      console.error(error.stack)
      throw error
    }
  }

  if (import.meta.env.SSR) {
    return new Promise((resolve, reject) => {
      onServerPrefetch(async () => {
        try {
          const result = await fetchRequest()
          resolve(result)
        } catch (e) {
          reject(e)
        }
      })
    })
  } else {
    try {
      return await fetchRequest()
    } catch (e) {
      throw e
    }
  }
}
