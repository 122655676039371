<template>
  <div ref="inputRef" class="w-full relative">
    <div class="relative" @click="open">
      <input
        v-show="!isOpen"
        :value="
          multiple && model !== null
            ? model.length
              ? `${plural(model.length, ['Выбрана', 'Выбрано', 'Выбрано'])} ${model.length} ${plural(model.length, ['запись', 'записи', 'записей'])}`
              : null
            : model
        "
        :placeholder="placeholder"
        class="!pr-8"
        :class="context.classes.input"
        :disabled="context.disabled"
        readonly
      />
      <input
        v-show="isOpen"
        ref="searchRef"
        v-model="search"
        :placeholder="'Поиск'"
        class="!pr-8"
        :class="context.classes.input"
        :disabled="context.disabled"
        @keydown.prevent.enter="searchEnter"
      />

      <button @click.prevent.stop="handleDecorationClick">
        <IconChevronDown
          v-if="
            !isOpen ||
            (multiple
              ? !model?.length && !search?.length
              : !model && !search?.length)
          "
          class="size-5"
          :class="[context.classes.decoration, { 'rotate-180': isOpen }]"
        />
        <IconX v-else :class="[context.classes.decoration]" />
      </button>
    </div>
    <Transition v-bind="fadeScale" mode="out-in">
      <ul
        v-if="isOpen && !grouped"
        class="absolute bg-background left-0 right-0 w-full translate-y-2 max-h-[200px] overflow-y-auto z-[1000] overscroll-contain"
        :class="cardStyle"
      >
        <li
          v-for="(option, i) in mapResult"
          :class="[
            context.classes.option,
            {
              selected: !multiple
                ? value === option[trackBy]
                : Array.isArray(value)
                  ? value.includes(option[trackBy])
                  : false,
            },
            selectionHover,
          ]"
          :key="i"
          @click="handleInput(option)"
        >
          <component
            v-if="context?.slots?.item"
            :is="context?.slots?.item"
            :option="option"
          />
          <div v-else class="flex gap-2 items-start">
            <component
              class="flex-none size-5"
              :is="
                (
                  !multiple
                    ? value === option[trackBy]
                    : Array.isArray(value)
                      ? value.includes(option[trackBy])
                      : false
                )
                  ? IconSquareCheck
                  : IconSquare
              "
            />
            <span v-text="option[labelBy]" />
          </div>
        </li>
        <li
          v-if="!mapResult.length"
          class="pointer-events-none"
          :class="[context.classes.option]"
        >
          <component v-if="context?.slots?.empty" :is="context?.slots?.item" />
          <span v-else v-text="emptyPlaceholder" />
        </li>
      </ul>
      <ul
        v-else-if="isOpen"
        class="absolute left-0 right-0 w-full translate-y-2 max-h-[200px] overflow-y-auto z-[1000] overscroll-contain"
        :class="cardStyle"
      >
        <li v-for="(item, i) in mapResult" :key="i">
          <div
            class="px-3 py-2 font-semibold text-black-400 text-sm bg-black-100"
            v-text="item.group"
          />
          <div
            v-for="(option, i) in item.options"
            :key="i"
            :class="[
              context.classes.option,
              {
                selected: value ? value.includes(option[trackBy]) : false,
              },
              selectionHover,
            ]"
            @click="handleInput(option)"
          >
            <component
              v-if="context?.slots?.item"
              :is="context?.slots?.item"
              :option="option"
            />
            <span v-else v-text="option[labelBy]" />
          </div>
        </li>
        <li
          v-if="!mapResult.length"
          class="pointer-events-none"
          :class="[context.classes.option]"
        >
          <component v-if="context?.slots?.empty" :is="context?.slots?.item" />
          <span v-else v-text="emptyPlaceholder" />
        </li>
      </ul>
    </Transition>
  </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core'
import { useFuse } from '@vueuse/integrations/useFuse'
import { computed, nextTick, ref } from 'vue'

import {
  IconChevronDown,
  IconSquare,
  IconSquareCheck,
  IconX,
} from '@tabler/icons-vue'

import { plural } from '@/utils/plural.js'
import { fadeScale } from '@/utils/transitions.js'

const cardStyle =
  'rounded-lg  dark:bg-primary-950 border border-black-100 dark:border-brand-3/10 shadow dark:shadow-brand-1/10'

const selectionHover =
  'transition-colors hover:text-primary hover:dark:text-white hover:bg-primary-50 hover:dark:bg-primary-900'

const props = defineProps({
  context: {
    type: Object,
    default: () => ({}),
  },
})

const inputRef = ref(null)
const searchRef = ref(null)
onClickOutside(inputRef, () => (isOpen.value = false))
const value = computed({
  get() {
    return props.context.value
  },
  set(value) {
    return value
  },
})
const placeholder = ref(props.context.placeholder || 'Выберите')
const trackBy = props.context.trackBy || 'value'
const labelBy = props.context.labelBy || 'label'
const emptyPlaceholder = props.context.emptyPlaceholder || 'Ничего не найдено'
const fuse = ref(null)
const search = ref('')
const isOpen = ref(false)
const multiple = ref(props.context.multiple || false)
const grouped = ref(props.context.grouped || false)
const options = computed(() => props.context?.options || [])
const model = computed(() => {
  // Check if options.value is an array and value.value is not null
  if (!Array.isArray(options.value) || value.value === null) {
    return null
  }
  // Check if value.value is an array for further use
  const isValueArray = Array.isArray(value.value)
  // Scenario for grouped.value
  if (grouped.value && isValueArray) {
    return options.value.flatMap((group) =>
      group.options.filter((option) => value.value.includes(option[trackBy])),
    )
  }
  // Scenario for multiple.value
  if (multiple.value && isValueArray) {
    return options.value.filter((option) =>
      value.value.includes(option[trackBy]),
    )
  }
  // Scenario for a single value (not an array)
  if (!isValueArray) {
    const modelItem = options.value.find(
      (option) => option[trackBy] === value.value,
    )
    // Return the label of the found model item or null if not found
    return modelItem ? modelItem[labelBy] : null
  }
  return null
})

function clearSearch() {
  search.value = ''
}

function searchEnter() {
  if (mapResult.value.length) {
    handleInput(mapResult.value[0])
  }
}

function handleDecorationClick() {
  if (!isOpen.value || !value.value || (grouped.value && !value.value.length)) {
    isOpen.value = !isOpen.value
    search.value = ''
  } else {
    isOpen.value = false
    search.value = ''
    value.value = null
    props.context.node.input(null)
  }
}

const fOptions = ref({
  fuseOptions: {
    keys: props.context.fuseKeys ?? [labelBy, `options.${labelBy}`],
    threshold: 0.1,
    isCaseSensitive: false,
    shouldSort: true,
    includeMatches: true,
  },
})

const { results } = useFuse(search, options, fOptions)

const mapResult = computed(() => {
  if (grouped.value && results.value.length) {
    return results.value.length
      ? results.value.map((item) => ({
          group: item.item.group,
          options: item.matches
            ?.filter((match) => match.key.includes('options'))
            .map((match) => item.item.options[match.refIndex]),
        }))
      : options.value
  }
  if (results.value.length) {
    return results.value.flatMap((item) => item.item)
  }
  return options.value
})

function handleInput(option) {
  if (!multiple.value) {
    isOpen.value = false
  }

  if (multiple.value) {
    const set = new Set(value.value ? [...value.value] : [])

    if (set.has(option[trackBy])) {
      set.delete(option[trackBy])
    } else {
      set.add(option[trackBy])
    }
    props.context.node.input([...set])
    return
  }
  if (option[trackBy] === value.value) {
    value.value = null
    props.context.node.input(null)
    return
  }
  value.value = option[trackBy]
  props.context.node.input(option[trackBy])
}

function open() {
  clearSearch()
  isOpen.value = !isOpen.value
  nextTick(() => {
    searchRef.value.focus()
  })
}
</script>
