<template>
  <div
    class="sticky top-0 z-[60] transition-colors py-4"
    :class="{
      'bg-background/80 backdrop-blur-lg shadow': !withoutProfile,
    }"
  >
    <div class="container flex items-center gap-4 justify-between">
      <Logo />

      <div class="flex items-center ml-auto">
        <ThemeSwitcher class="mr-2" />
        <Profile v-if="!withoutProfile" />
        <Button
          v-if="auth.isLoggedIn"
          @click="auth.logout"
          class="ml-2 !px-2.5"
          type="secondary"
        >
          <IconLogout class="w-5 h-5" />
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from '@/stores/auth.js'

import { IconLogout } from '@tabler/icons-vue'

import Profile from '@/components/common/Profile.vue'
import ThemeSwitcher from '@/components/common/ThemeSwitcher.vue'
import { Button } from '@/components/ui/Button'

import Logo from './Logo.vue'

const props = defineProps({
  withoutProfile: {
    type: Boolean,
    default: false,
  },
})

const auth = useAuthStore()
</script>
